import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import { toast } from "react-toastify";
import validator from "validator";
import { Helmet } from "react-helmet";

export default function HealthCard() {
  const [cards, setCards] = useState([]);
  const [adminData, setAdminData] = useState({});
  const [loading, setLoading] = useState(true);
  const { token, admin } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchAdminData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getadmin`,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setAdminData(response.data);
      } else {
        console.log("Failed to fetch admin data");
      }
    } catch (error) {
      console.log(
        `Error: ${error.response ? error.response.data.message : "An error occurred"
        }`
      );
    }
  };

  const fetchCards = async (page = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/healthcard/admin?page=${page}&limit=${limit}`,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setCards(response.data.cards);
        setCurrentPage(page);
        setTotalPages(response?.data?.pagination.totalPages);
      } else {
        console.log("Failed to fetch Smart Health Cards");
      }
    } catch (error) {
      console.log(
        `Error: ${error.response ? error.response.data.message : "An error occurred"
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    const handlePageChange = (page) => {
      if (page < 1 || page > totalPages) return;
      fetchCards(page);
    };

    return (
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
            </li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </li>
          </>
        )}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };




  useEffect(() => {
    fetchAdminData();
    fetchCards();
  }, [token, limit]);

  const [startRange, setStartRange] = useState("");
  const [endRange, setEndRange] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    startRange: "",
    endRange: "",
    phone: "",
  });

  const validateInputs = () => {
    let isValid = true;
    const newErrors = { startRange: "", endRange: "", phone: "" };

    if (!validator.isLength(phone, { min: 10, max: 10 })) {
      newErrors.phone = "Invalid Phone Number";
      isValid = false;
    }

    if (
      !validator.isLength(startRange, { min: 10, max: 10 }) ||
      !validator.isLength(endRange, { min: 10, max: 10 })
    ) {
      newErrors.startRange = "Start Range should be in the format BH00000001";
      newErrors.endRange = "End Range should be in the format BH99999999";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/healthcard/${adminData.admin_type === "clinicadmin" ? "clinic" : "salesrep"}/generate`,
        {
          start_range: startRange,
          end_range: endRange,
          phone,
        },
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setStartRange("");
        setEndRange("");
        setPhone("");
        fetchCards();
      }
    } catch (error) {
      const errorMessage = error.response?.message || "Error Generating Cards";

      // Check for specific error message related to range already being generated
      if (errorMessage.includes("Some or all health cards in the specified range have already been generated for this sales rep")) {
        toast.error("Cards already generated in this range.");
      } else {
        toast.error(errorMessage);
      }
    }
  };



  return (
    <>
      <Helmet>
        <title>Generate Health Cards</title>
      </Helmet>

      <div className="card">
        <div className="card-header border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Health Card</h5>
          <div className="add-new">
            {admin?.role?.permissions?.ADMIN_PANEL?.sales?.generate_card_salesrep &&
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addNewCCModal"
              >
                Generate Cards
              </button>
            }
          </div>

        </div>
        <div className="card-datatable table-responsive">
          <div
            id="DataTables_Table_0_wrapper"
            className="dataTables_wrapper dt-bootstrap5 no-footer"
          >

            <div
              className="modal fade"
              id="addNewCCModal"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                <div className="modal-content">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                  <div className="modal-body p-0">
                    <div className="text-center mb-6">
                      <h4 className="mb-2">Add New Smart Health Card</h4>
                      <p>Add new card to avail benefits</p>
                    </div>
                    <form
                      id="addNewCCForm"
                      className="row g-5"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-6">
                        <div className="input-group input-group-merge">
                          <div className="form-floating form-floating-outline">
                            <input
                              id="startRange"
                              name="startRange"
                              className={`form-control ${errors.startRange ? "is-invalid" : ""
                                }`}
                              type="text"
                              placeholder="Start Range (e.g. BH00000001)"
                              value={startRange}
                              onChange={(e) => setStartRange(e.target.value)}
                              aria-describedby="modalAddCard1"
                            />
                            <label htmlFor="startRange">Start Range</label>
                            {errors.startRange && (
                              <div className="invalid-feedback">
                                {errors.startRange}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="input-group input-group-merge">
                          <div className="form-floating form-floating-outline">
                            <input
                              id="endRange"
                              name="endRange"
                              className={`form-control ${errors.endRange ? "is-invalid" : ""
                                }`}
                              type="text"
                              placeholder="End Range (e.g. BH99999999)"
                              value={endRange}
                              onChange={(e) => setEndRange(e.target.value)}
                              aria-describedby="modalAddCard2"
                            />
                            <label htmlFor="endRange">End Range</label>
                            {errors.endRange && (
                              <div className="invalid-feedback">
                                {errors.endRange}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="input-group input-group-merge">
                          <div className="form-floating form-floating-outline">
                            <input
                              id="phone"
                              name="phone"
                              className={`form-control ${errors.phone ? "is-invalid" : ""
                                }`}
                              type="text"
                              placeholder="Phone Number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              aria-describedby="modalAddCard3"
                            />
                            <label htmlFor="phone">Phone Number</label>
                            {errors.phone && (
                              <div className="invalid-feedback">
                                {errors.phone}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                        <button
                          type="reset"
                          className="btn btn-outline-secondary btn-reset"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    Show{" "}
                    <select
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="form-select form-select-sm"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Card Number</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Generated On</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5">Loading...</td>
                  </tr>
                ) : (
                  cards &&
                  cards.map((card, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      <td>{card.card_number}</td>
                      <td>{card.type}</td>
                      <td
                        className={
                          card.activated ? "text-success" : "text-danger"
                        }
                      >
                        {card.activated ? (
                          <span className="badge bg-label-success rounded-pill">
                            Activated
                          </span>
                        ) : (
                          <span className="badge bg-label-danger rounded-pill">
                            Not Activated
                          </span>
                        )}
                      </td>
                      <td>
                        {new Date(card.created_at).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="row mt-3">
              <nav aria-label="Page navigation">
                {renderPagination()}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
