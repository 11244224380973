import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../components/utils/loggedin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Account({ admins = {}, fetchAdminData }) {
  console.log(admins)

  const [formdata, setFormData] = useState({
    first_name: admins?.first_name || "",
    last_name: admins?.last_name || "",
    email: admins?.email || "",
    phone: admins?.phone || "",
    gender: admins?.gender || "",

    dob: admins?.dob || "",
    address: admins?.address || "",
    state: admins?.state || "",
    city: admins?.city || "",
    zip: admins?.zip || "",
    role_id: admins?.role_id || "",
    created_at: admins?.created_at || "",
  });


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}/${month}/${year}`;
  };

  const [otp, setOtp] = useState(""); // Initialize as a string
  const [errorMessage, setErrorMessage] = useState("");

  // this is the resend otp state 
  const [resendTimer, setResendTimer] = useState(0); // Timer for resend
  const [resendDisabled, setResendDisabled] = useState(false); // Flag for resend button

  const { token, admin } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.name]: e.target.value });
  };
  // getting all roles here 
  const [roles, setRoles] = useState([]);
  const getAllRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/role`, {
        headers: {
          Signature: process.env.REACT_APP_API_SIGNATURE,
          Authorization: `Bearer ${token}`
        }
      })
      setRoles(response.data);
    } catch (error) {
      console.log("Error Fetching Roles", error);
    }
  }

  useEffect(() => {
    getAllRoles();
  }, [token])


  const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return; // Prevent non-numeric input

    const otpArray = otp.split(""); // Ensure otp is a string
    otpArray[index] = value;

    // Combine the array back into a string
    setOtp(otpArray.join(""));

    // Focus on next input
    if (e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/verifyotp`,
        { phone: admins.phone, otp },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      console.log(response.data);
      toast.success(response.data.message);
      // Handle success (e.g., show a success message, redirect, etc.)
    } catch (error) {
      console.error(error);
      setErrorMessage("Invalid OTP or OTP expired");
      // Handle error (e.g., show an error message)
    }
  };

  const verifyAdmin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/verifyadmin`,
        {
          phone: admins.phone,
          otp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchAdminData();
        document.getElementById("verifyOTP").classList.remove("show");
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop").remove();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initiateVerification = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/initiateadminverification`,
        {
          phone: admins.phone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setResendTimer(10); // Set timer for 30 seconds
        setResendDisabled(true); // Disable resend button
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/initiateadminverification`,
        { phone: admins.phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      if (response.status === 200) {
        toast.success("OTP has been resent. Please check your phone.");
        setResendTimer(10); // Reset timer for 30 seconds
        setResendDisabled(true); // Disable resend button
      } else {
        toast.error("Failed to resend OTP.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while resending OTP.");
    }
  };


  const updateAdminData = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/update/${admins.id}`,
        formdata, // formdata now includes first_name and last_name
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Update successful:", response.data);
      toast.success(response.data.message);
      document.getElementById("offcanvasAddUser").classList.remove("show");
      document.body.classList.remove("modal-open");
      document.querySelector(".modal-backdrop").remove();

    } catch (error) {
      console.error("Error updating admin data:", error);

    }
  };

  //   this is the api call for unverify admin
  const unverifyAdmin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/unverifyadmin/${admins.id}`, // Endpoint for unverification
        {
          id: admins.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization token
            Signature: process.env.REACT_APP_API_SIGNATURE, // Signature for additional security
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message); // Success message
        document.getElementById("suspendAdmin").classList.remove("show");
        document.body.classList.remove("modal-open");
        document.querySelector(".modal-backdrop").remove();
        fetchAdminData();
      } else {
        toast.error(response.data.message); // Error message
      }
    } catch (error) {
      console.error("Error during admin unverification:", error);
      toast.error("An error occurred during admin unverification."); // Generic error message
    }
  };

  useEffect(() => {
    setFormData(admins);
  }, [admins]);


  // this is the useeffect for the resend otp purpose
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer on unmount
    } else {
      setResendDisabled(false); // Re-enable resend button when timer reaches 0
    }
  }, [resendTimer]);

  return (
    <>
      {/* Project table */}
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Details</h5>
          <div className="d-flex">
            {admin?.role?.permissions?.ADMIN_PANEL?.teams?.teams_edit &&
              <button
                className="btn  btn-label-primary me-2"
                data-bs-target="#offcanvasAddUser"
                data-bs-toggle="offcanvas"
              >
                <i class="ri-pencil-line me-2"></i>
                Edit
              </button>}
            {
              <>

                {
                  admin?.role?.permissions?.ADMIN_PANEL?.teams?.teams_suspend &&
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#suspendAdmin"
                    className="btn btn-outline-danger suspend-user"
                  >
                    Suspend
                  </button>}
                :
                {admin?.role?.permissions?.ADMIN_PANEL?.teams?.teams_verify &&
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#verifyOTP"
                    className="btn btn-outline-success suspend-user"
                    onClick={() => initiateVerification()}
                  >
                    Verify
                  </button>}

              </>
            }
          </div>
        </div>
        <div className="table-responsive text-nowrap">
          <table className="table">
            <tbody className="table-border-bottom-0">
              <tr>
                <td>
                  <span className="fw-medium">Username</span>
                </td>
                <td>{admins?.username || "BHADXXXX"}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Email</span>
                </td>
                <td>{admins?.email}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Status</span>
                </td>
                <td
                  className={`${admins?.verified ? "text-success" : "text-danger"
                    }`}
                >
                  {admins?.verified ? "Verified" : "Not Verified"}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Role</span>
                </td>
                <td>{admins?.role?.name}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Contact</span>
                </td>
                <td>{admins?.phone}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Address</span>
                </td>
                <td>
                  {admins?.address +
                    " " +
                    admins?.state +
                    " " +
                    admins?.city +
                    " " +
                    admins?.zip}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Joined On</span>
                </td>
                <td>
                  {formatDate(admins?.created_at)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      {/* Edit User Modal */}
      <div
        className="offcanvas offcanvas-end addadmin"
        tabIndex={-1}
        id="offcanvasAddUser"
        aria-labelledby="offcanvasAddUserLabel"
      // Adjust this value as needed
      >
        <div className="offcanvas-header border-bottom">
          <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
            Edit User
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body mx-0 flex-grow-0 h-100">
          <form
            className="add-new-user pt-0"
            id="editUser"
            onSubmit={(e) => {
              e.preventDefault();
              updateAdminData();  // Pass the formdata to the update function
            }}
          >
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                id="add-user-firstname"
                className="form-control"
                name="first_name"
                value={formdata?.first_name}
                onChange={handleChange}
              />
              <label htmlFor="add-user-firstname">First Name</label>
            </div>
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                id="add-user-lastname"
                className="form-control"
                name="last_name"
                value={formdata.last_name}
                onChange={handleChange}
              />
              <label htmlFor="add-user-lastname">Last Name</label>
            </div>
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                id="add-user-email"
                className="form-control"
                name="email"
                value={formdata.email}
                onChange={handleChange}
              />
              <label htmlFor="add-user-email">Email</label>
            </div>

            <div className="form-floating form-floating-outline mb-5">
              <select
                id="modalEditUserStatus"
                name="role_id"
                className="form-select"
                aria-label="Default select example"
                value={formdata.role_id}
                onChange={handleChange}
              >
                <option value="">Select Role</option>
                {
                  roles.map((role, index) => (
                    <option key={index} value={role.id}>{role.name}</option>
                  ))
                }
              </select>
              <label htmlFor="modalEditUserStatus">Role</label>
            </div>

            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                id="add-user-contact"
                className="form-control"
                name="phone"
                value={formdata.phone}
                onChange={handleChange}
              />
              <label htmlFor="add-user-contact">Contact</label>
            </div>

            <div className="form-floating form-floating-outline mb-5">
              <textarea
                id="TagifyLanguageSuggestion"
                name="address"
                className="form-control h-auto"
                value={formdata.address}
                onChange={handleChange}
              />
              <label htmlFor="TagifyLanguageSuggestion">Address</label>
            </div>

            <div className="form-floating form-floating-outline mb-5">
              <input
                id="TagifyLanguageSuggestionState"
                name="state"
                className="form-control h-auto"
                value={formdata.state}
                onChange={handleChange}
              />
              <label htmlFor="TagifyLanguageSuggestionState">State</label>
            </div>

            <div className="form-floating form-floating-outline mb-5">
              <input
                id="TagifyLanguageSuggestionCity"
                name="city"
                className="form-control h-auto"
                value={formdata.city}
                onChange={handleChange}
              />
              <label htmlFor="TagifyLanguageSuggestionCity">City</label>
            </div>

            <div className="form-floating form-floating-outline mb-5">
              <input
                id="TagifyLanguageSuggestionZip"
                name="zip"
                className="form-control h-auto"
                value={formdata.zip}
                onChange={handleChange}
              />
              <label htmlFor="TagifyLanguageSuggestionZip">Zip</label>
            </div>

            <div className="col-12 text-center d-flex flex-wrap justify-content-center gap-4 row-gap-4">
              <button
                type="submit"
                className="btn btn-success me-sm-3 me-1 data-submit"
              >
                Update
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary"
                data-bs-dismiss="offcanvas"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>






      {/* Verify OTP Modal */}
      <div
        className="modal fade"
        id="verifyOTP"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="card-body mt-1">
              <h4 className="mb-1"> Verification of the user 💬</h4>
              <p className="text-start mb-5">
                We sent a verification code to your mobile. Enter the code from
                the mobile in the field below.
                <span className="d-block mt-1 h6">{admin.phone}</span>
              </p>
              <p className="mb-0">Type your 6 digit security code</p>
              <form id="twoStepsForm" onSubmit={verifyAdmin}>
                <div className="mb-3">
                  <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        type="tel"
                        className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                        maxLength={1}
                        value={otp[index] || ""}
                        onChange={(e) => handleChangeOtp(e, index)}
                        onFocus={(e) => e.target.select()}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  {/* Hidden field not necessary when using string */}
                </div>
                <button
                  className="btn btn-primary d-grid w-100 mb-5"
                  type="submit"
                >
                  Verify my account
                </button>
                <div className="text-center">
                  Didn't get the code?
                  {resendTimer > 0 ? (
                    <span style={{ color: "blue", padding: "2px" }}>Resend in {resendTimer}s</span>
                  ) : (
                    <a href="javascript:void(0);" onClick={resendOtp}>Resend</a>
                  )}
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Suspend Admin Modal */}
      <div
        className="modal fade"
        id="suspendAdmin"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body p-0">
              <div className="text-center mb-6">
                <h2 className="mb-2">Are you sure !</h2>
                {/* <p>Verify Your Mobile Number for SMS</p> */}
              </div>
              <p className="mb-5 text-center">
                Are you sure you want to unverify this admin account?
                <br /> Please confirm your decision to proceed with the
                unverification process.
              </p>

              <form
                id="enableOTPForm"
                className="row g-5"
                onsubmit="return false"
              >
                {/* <div className="col-12">
                  <div className="input-group input-group-merge">
                    <span className="input-group-text">US (+1)</span>
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="modalEnableOTPPhone"
                        name="modalEnableOTPPhone"
                        className="form-control phone-number-otp-mask"
                        placeholder="202 555 0111"
                      />
                      <label htmlFor="modalEnableOTPPhone">Phone Number</label>
                    </div>
                  </div>
                </div> */}
                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => unverifyAdmin(e)}
                  >
                    Unverify
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline-danger"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
