import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/utils/loggedin";
import axios from "axios";
import { Helmet } from "react-helmet";
import * as XLSX from "xlsx";

export default function AllAdmins({ admins, loading }) {
  const navigate = useNavigate();
  const pages = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchUsername, setSearchUsername] = useState("");
  const [searchAdminType, setSearchAdminType] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchVerified, setSearchVerified] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(loading);
  const [noDataFound, setNoDataFound] = useState(false);
  const [adminsPerPage, setAdminsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [roles, setRoles] = useState([]);


  const { token, admin } = useAuth();

  // Fetch initial admin data or update when props change
  useEffect((page = 1) => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/getalladmins?page=${page}&limit=${limit}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        })
        .then((response) => {
          setAdminData(response.data.admins);
          setSearchLoading(false);
          setCurrentPage(page);
          setTotalPages(response?.data?.pagination.totalPages);
        })
        .catch((error) => {
          console.error("Error fetching admins:", error);
          setSearchLoading(false);
        });
    }
  }, [token, limit]);

  // Update admin data when search parameters change
  useEffect(() => {
    handleSearch();
  }, [
    searchUsername,
    searchName,
    searchAdminType,
    searchPhone,
    searchVerified,
  ]);

  // const totalPages = Math.ceil(adminData.length / adminsPerPage);

  // const indexOfLastAdmin = currentPage * adminsPerPage;
  // const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
  // const currentAdmins = Array.isArray(adminData)
  //   ? adminData.slice(indexOfFirstAdmin, indexOfLastAdmin)
  //   : [];

  function ViewAdmin(adminId) {
    navigate("/admins/view", { state: { adminId } });
  }

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  const handleSearch = async () => {
    setSearchLoading(true);
    setNoDataFound(false);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/search`,
        {
          params: {
            username: searchUsername,
            name: searchName,
            admin_type: searchAdminType !== "" ? searchAdminType : undefined,
            phone: searchPhone,
            verified: searchVerified !== "" ? searchVerified : undefined, // Ensure that no value means "all"
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );

      console.log("Search API Response:", response.data); // Log search API response
      if (response.status === 200) {
        setAdminData(response.data.admins);
        if (response.data.admins.length === 0) {
          setNoDataFound(true);
        }
      } else {
        setNoDataFound(true);
      }
    } catch (error) {
      console.error("Error during search:", error);
      setAdminData([]);
      setNoDataFound(true);
    } finally {
      setSearchLoading(false);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    const handlePageChange = (page) => {
      if (page < 1 || page > totalPages) return;
      setAdminData(page);
    };

    return (
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
            </li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </li>
          </>
        )}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };

  // Reset currentPage if it exceeds totalPages after filtering
  useEffect(() => {
    if (currentPage > 1 && currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [currentPage, totalPages]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    return `${day}/${month}/${year}`;
  }


  const exportToExcel = () => {
    // Prepare data for export
    const exportData = adminData.map((admin, index) => ({
      S_No: (currentPage - 1) * pages + index + 1,
      Username: admin.username ? admin.username : "BHADXXXX",
      Name: `${admin.first_name} ${admin.last_name}`,
      Role: admin.role?.name,
      Phone: admin.phone,
      Status: admin.verified ? "Verified" : "Not Verified",
      Created: formatDate(admin.created_at),
    }));

    // Create a new worksheet
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Admin Data");

    // Generate an Excel file and trigger download
    XLSX.writeFile(wb, "admin_data.xlsx");
  };


  const [selectedRole, setSelectedRole] = useState('');
  const getAllRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/role`, {
        headers: {
          Signature: process.env.REACT_APP_API_SIGNATURE,
          Authorization: `Bearer ${token}`
        }
      })
      setRoles(response.data);
    } catch (error) {
      console.log("Error Fetching Roles", error);
    }
  }

  useEffect(() => {
    getAllRoles();
  }, [token])

  return (
    <>
      <Helmet>Main Admin</Helmet>
      <div className="row g-3 p-4">
        <div className="col-12 d-flex justify-content-between align-items-center flex-wrap">
          {/* Dropdown for items per page */}
          <div className="d-flex align-items-center mb-2 mb-md-0">
            <label className="d-flex align-items-center mb-0">
              Show
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="form-select form-select-sm ms-2 me-2"
                value={limit}
                onChange={(e) => setLimit(parseInt(e.target.value, 10))}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </select>
              entries
            </label>
          </div>

          {/* Button for export to Excel */}
          <div className="text-end">
            <button className="btn btn-primary" onClick={exportToExcel}>
              Export to Excel
            </button>
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>S.No</th>
            <th>Username</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Role</th>
            <th>Created</th>
            {admin?.role?.permissions?.ADMIN_PANEL?.teams?.teams_view &&
              <th>Actions</th>}

          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Username"
                    aria-controls="DataTables_Table_0"
                    value={searchUsername}
                    onChange={(e) => setSearchUsername(e.target.value)}
                  />
                  <label htmlFor="search-username"> Username</label>
                </div>
              </form>
            </td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Name"
                    aria-controls="DataTables_Table_0"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                  <label htmlFor="search-name"> Name</label>
                </div>
              </form>
            </td>
            <td>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
                className="d-flex flex-wrap gap-3"
              >
                <div className="form-floating form-floating-outline">
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    placeholder="Search Phone"
                    aria-controls="DataTables_Table_0"
                    value={searchPhone}
                    onChange={(e) => setSearchPhone(e.target.value)}
                  />
                  <label htmlFor="search-phone"> Phone</label>
                </div>
              </form>
            </td>
            <td>
              <form className="d-flex flex-wrap gap-3 mb-0">
                <div className="form-floating form-floating-outline">
                  <select
                    className="form-select form-control-sm"
                    id="search-status"
                    value={searchVerified}
                    onChange={(e) => setSearchVerified(e.target.value)}
                    style={{ width: "140px" }} // Adjust width if necessary
                  >
                    <option value="">Select Status</option>
                    <option value="true">Verified</option>
                    <option value="false">Unverified</option>
                  </select>
                  <label htmlFor="search-status"> Status</label>
                </div>
              </form>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {searchLoading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : noDataFound ? (
            <tr>
              <td colSpan="7" className="text-center text-muted">
                No data found
              </td>
            </tr>
          ) : (
            adminData.map((admins, index) => (
              <tr key={admins.id}>
                <td>{(currentPage - 1) * pages + index + 1}</td>
                <td>{admins.username ? admins.username : 'BHADXXXX'}</td>
                <td>
                  {admins.first_name} {admins.last_name}
                </td>
                <td>{admins.phone}</td>
                <td className={admins.verified ? "text-success" : "text-danger"}>
                  {admins.verified ? (
                    <span className="badge bg-label-success rounded-pill">
                      Verified
                    </span>
                  ) : (
                    <span className="badge bg-label-danger rounded-pill">
                      Not Verified
                    </span>
                  )}
                </td>
                <td>{admins.role?.name}</td>
                <td>{formatDate(admins.created_at)}</td>
                <td>
                  {admin?.role?.permissions?.ADMIN_PANEL?.teams?.teams_view &&
                    <button className="btn" onClick={() => ViewAdmin(admins.id)}>
                      <i className="ri-eye-line"></i>
                    </button>}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="row mt-3">
        <nav aria-label="Page navigation">
          {renderPagination()}
        </nav>
      </div>

    </>
  );
}
