import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import validator from "validator";
import { useNavigate } from "react-router-dom";

const AddAdmin = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    role_id: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    banking_name: "",
    ifsc_code: "",
    account_number: "",
    pan_number: "",
  });
  const [errors, setErrors] = useState({});
  const [isLocating, setIsLocating] = useState(false);
  const [locationError, setLocationError] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let newErrors = {};
    const {
      first_name,
      last_name,
      phone,
      role_id,
      email,
      password,
      confirmPassword,
      address,
      city,
      state,
      zip,
      banking_name,
      ifsc_code,
      account_number,
      pan_number,
    } = formData;

    if (!validator.isEmail(email)) {
      newErrors.email = "Invalid email address";
    }
    if (validator.isEmpty(password)) {
      newErrors.password = "Password is required";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (validator.isEmpty(first_name)) {
      newErrors.first_name = "First name is required";
    }
    if (validator.isEmpty(last_name)) {
      newErrors.last_name = "Last name is required";
    }
    if (!validator.isMobilePhone(phone, "en-IN")) {
      newErrors.phone = "Invalid phone number";
    }
    if (validator.isEmpty(role_id)) {
      newErrors.role_id = "Admin type is required";
    }
    if (validator.isEmpty(address)) {
      newErrors.address = "Address is required";
    }
    if (validator.isEmpty(city)) {
      newErrors.city = "City is required";
    }
    if (validator.isEmpty(state)) {
      newErrors.state = "State is required";
    }
    if (validator.isEmpty(zip) || !validator.isPostalCode(zip, "IN")) {
      newErrors.zip = "Invalid zip code";
    }
    if (validator.isEmpty(banking_name)) {
      newErrors.banking_name = "Banking name is required";
    }
    if (validator.isEmpty(ifsc_code)) {
      newErrors.ifsc_code = "IFSC code is required";
    }
    if (validator.isEmpty(account_number)) {
      newErrors.account_number = "Account number is required";
    }
    if (validator.isEmpty(pan_number)) {
      newErrors.pan_number = "PAN number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [roles, setRoles] = useState([]);
  const getAllRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/role`, {
        headers: {
          Signature: process.env.REACT_APP_API_SIGNATURE,
          Authorization: `Bearer ${token}`
        }
      })
      setRoles(response.data);
    } catch (error) {
      console.log("Error Fetching Roles", error);
    }
  }

  useEffect(() => {
    getAllRoles();
  }, [token])

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/register`,
        formData,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        const phone = formData.phone;
        navigate("/admins/verify/otp", { state: { phone } });
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert("Error registering admin");
    }
  };

  const fetchCurrentLocation = () => {
    setIsLocating(true);
    setLocationError("");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
            .then((response) => response.json())
            .then((data) => {
              const { address } = data;
              setFormData((prevData) => ({
                ...prevData,
                address: `${address.road || ""}, ${address.suburb || ""}`,
                city: address.city || address.town || address.village || "",
                state: address.state || "",
                zip: address.postcode || "",
              }));
              setIsLocating(false);
            })
            .catch((error) => {
              console.error("Error fetching location data:", error);
              setLocationError("Failed to fetch address from location.");
              setIsLocating(false);
            });
        },
        (error) => {
          console.error("Geolocation error:", error);
          setLocationError("Unable to access your location.");
          setIsLocating(false);
        }
      );
    } else {
      setLocationError("Geolocation is not supported by your browser.");
      setIsLocating(false);
    }
  };



  return (
    <div className="authentication-wrapper authentication-cover">
      <div className="authentication-inner row m-0">
        <div className="d-flex justify-content-start authentication-bg p-5">
          <div className="w-px-700  mt-lg-0 pt-lg-0 pt-4">
            <div className="card mb-6">
              <h5 className="card-header border border-bottom-6">Add Team</h5>
              <form className="card-body pt-2" onSubmit={handleSubmit}>
                <h6>1. Account Details</h6>
                <div className="row g-6">
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className={`form-control ${errors.email ? "is-invalid" : ""
                          }`}
                        placeholder="john.doe@example.com"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <label htmlFor="email">Email</label>
                      {errors.email && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className={`form-control ${errors.password ? "is-invalid" : ""
                          }`}
                        placeholder="••••••••"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <label htmlFor="password">Password</label>
                      {errors.password && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
                          }`}
                        placeholder="••••••••"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <label htmlFor="confirmPassword">
                        Confirm Password
                      </label>
                      {errors.confirmPassword && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <hr className="my-6 mx-n4" />
                <h6>2. Personal Information</h6>
                <div className="row g-6">
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        className={`form-control ${errors.first_name ? "is-invalid" : ""
                          }`}
                        placeholder="John"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="first_name">First Name</label>
                      {errors.first_name && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.first_name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        className={`form-control ${errors.last_name ? "is-invalid" : ""
                          }`}
                        placeholder="Doe"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="last_name">Last Name</label>
                      {errors.last_name && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.last_name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className={`form-control ${errors.phone ? "is-invalid" : ""
                          }`}
                        placeholder="+91"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      <label htmlFor="phone">Phone</label>
                      {errors.phone && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <select
                        id="role_id"
                        name="role_id"
                        className={`form-control ${errors.role_id ? "is-invalid" : ""
                          }`}
                        value={formData.role_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Role</option>
                        {
                          roles.map((role, index) => (
                            <option key={index} value={role.id}>{role.name}</option>
                          ))
                        }
                      </select>
                      <label htmlFor="role_id">Role</label>
                      {errors.role_id && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.role_id}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <hr className="my-6 mx-n4" />
                <h6>3. Address Information</h6>
                <div className="row g-6">
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className={`form-control ${errors.address ? "is-invalid" : ""}`}
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                      <label htmlFor="address">Address</label>
                      {errors.address && (
                        <div className="invalid-feedback" style={{ color: "red" }}>
                          {errors.address}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className={`form-control ${errors.city ? "is-invalid" : ""}`}
                        placeholder="City"
                        value={formData.city}
                        onChange={handleChange}
                      />
                      <label htmlFor="city">City</label>
                      {errors.city && (
                        <div className="invalid-feedback" style={{ color: "red" }}>
                          {errors.city}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="state"
                        name="state"
                        className={`form-control ${errors.state ? "is-invalid" : ""}`}
                        placeholder="State"
                        value={formData.state}
                        onChange={handleChange}
                      />
                      <label htmlFor="state">State</label>
                      {errors.state && (
                        <div className="invalid-feedback" style={{ color: "red" }}>
                          {errors.state}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        className={`form-control ${errors.zip ? "is-invalid" : ""}`}
                        placeholder="ZIP Code"
                        value={formData.zip}
                        onChange={handleChange}
                      />
                      <label htmlFor="zip">ZIP Code</label>
                      {errors.zip && (
                        <div className="invalid-feedback" style={{ color: "red" }}>
                          {errors.zip}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pt-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={fetchCurrentLocation}
                    disabled={isLocating}
                  >
                    {isLocating ? "Locating..." : "Use My Location"}
                  </button>
                  {locationError && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {locationError}
                    </div>
                  )}
                </div>





                <hr className="my-6 mx-n4" />
                <h6>4. Banking Information</h6>
                <div className="row g-6">
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="banking_name"
                        name="banking_name"
                        className={`form-control ${errors.banking_name ? "is-invalid" : ""
                          }`}
                        placeholder="Bank Name"
                        value={formData.banking_name}
                        onChange={handleChange}
                      />
                      <label htmlFor="banking_name">Banking Name</label>
                      {errors.banking_name && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.banking_name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="ifsc_code"
                        name="ifsc_code"
                        className={`form-control ${errors.ifsc_code ? "is-invalid" : ""
                          }`}
                        placeholder="IFSC Code"
                        value={formData.ifsc_code}
                        onChange={handleChange}
                      />
                      <label htmlFor="ifsc_code">IFSC Code</label>
                      {errors.ifsc_code && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.ifsc_code}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="account_number"
                        name="account_number"
                        className={`form-control ${errors.account_number ? "is-invalid" : ""
                          }`}
                        placeholder="Account Number"
                        value={formData.account_number}
                        onChange={handleChange}
                      />
                      <label htmlFor="account_number">Account Number</label>
                      {errors.account_number && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.account_number}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="pan_number"
                        name="pan_number"
                        className={`form-control ${errors.pan_number ? "is-invalid" : ""
                          }`}
                        placeholder="PAN Number"
                        value={formData.pan_number}
                        onChange={handleChange}
                      />
                      <label htmlFor="pan_number">PAN Number</label>
                      {errors.pan_number && (
                        <div
                          className="invalid-feedback"
                          style={{ color: "red" }}
                        >
                          {errors.pan_number}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="pt-6">
                  <button type="submit" className="btn btn-primary me-4">
                    Submit
                  </button>
                  <button type="reset" className="btn btn-outline-secondary" onClick={() => navigate("/admins")}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
