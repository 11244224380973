import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useAuth } from '../../../components/utils/loggedin';

export default function AllDoctors() {
    const { token, admin } = useAuth()
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [doctorsPerPage, setDoctorsPerPage] = useState(5); // Initial value set to 5
    const [searchName, setSearchName] = useState('');
    const [searchUsername, setSearchUsername] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [searchOnboarded, setSearchOnboarded] = useState('');
    const [totalItems, setTotalItems] = useState(0);


    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const navigate = useNavigate();

    const fetchDoctorData = (page = 1) => {
        axios.get(`${process.env.REACT_APP_API_URL}/doctor/getalldoctorsbyadmin?page=${page}&limit=${limit}`, {
            headers: {
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                setDoctors(response.data.doctors);
                setLoading(false);
                setCurrentPage(page);
                setTotalPages(response?.data?.pagination?.totalPages || 1);
                setTotalItems(response?.data?.pagination?.totalItems || 0);
            })
            .catch(error => {
                console.error('Error fetching doctors:', error);
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchDoctorData();
    }, [token, limit]);

    function ViewDoctor(doctorId) {
        navigate('/admins/doctors/view', { state: { doctorId } });
    }

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchDoctorData(page);
    };

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
        const pageNumbers = [];
        const maxPageButtons = 5; // The number of visible page buttons
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        // Generate page number buttons
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* Display first page and ellipsis if necessary */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page numbers */}
                {pageNumbers}

                {/* Display last page and ellipsis if necessary */}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    const filteredDoctors = doctors.filter(doctor => {
        const nameMatch = (doctor.first_name || '').toLowerCase().includes(searchName.toLowerCase()) || (doctor.last_name || '').toLowerCase().includes(searchName.toLowerCase());
        const usernameMatch = (doctor.username || '').toLowerCase().includes(searchUsername.toLowerCase());
        const phoneMatch = (doctor.phone || '').includes(searchPhone);
        const statusMatch = searchStatus ? (doctor.verified ? 'Verified' : 'Not Verified') === searchStatus : true;
        const onboardedMatch = searchOnboarded ? (doctor.onboarded ? 'Onboarded' : 'Not Onboarded') === searchOnboarded : true;

        return nameMatch && usernameMatch && phoneMatch && statusMatch && onboardedMatch;
    });

    // const totalPages = Math.ceil(filteredDoctors.length / doctorsPerPage);
    const indexOfLastDoctor = currentPage * doctorsPerPage;
    const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
    const currentDoctors = filteredDoctors.slice(indexOfFirstDoctor, indexOfLastDoctor);

    const exportToExcel = () => {
        const dataToExport = currentDoctors.map((doctor, index) => ({
            'S.No': indexOfFirstDoctor + index + 1,
            'Username': doctor.username ? doctor.username : 'BHDRXXXX',
            'Name': `${doctor.first_name} ${doctor.last_name}`,
            'Phone': doctor.phone,
            'Verified': doctor.verified ? 'Verified' : 'Not Verified',
            'Onboarded': doctor.onboarded ? 'Onboarded' : 'Not Onboarded',
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Doctors');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'doctors_data.xlsx');
    };


    return (
        <>
            <Helmet>
                <title>Manage Doctors Page</title>
            </Helmet>
            <div className="row g-6 mb-6">
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Total Doctors</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-2">{doctors.length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded-3">
                                        <div className="ri-group-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Verification</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{doctors.filter(doctor => !doctor.verified).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-danger rounded-3">
                                        <div className="ri-user-add-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Active Doctors</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{doctors.filter(doctor => doctor.onboarded).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-success rounded-3">
                                        <div className="ri-user-follow-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="me-1">
                                    <p className="text-heading mb-1">Pending Onboarding</p>
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-1 me-1">{doctors.filter(doctor => !doctor.onboarded).length}</h4>
                                    </div>
                                </div>
                                <div className="avatar">
                                    <div className="avatar-initial bg-label-warning rounded-3">
                                        <div className="ri-user-search-line ri-26px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header border-bottom d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">Doctors</h5>
                    {admin?.role?.permissions?.ADMIN_PANEL?.doctor?.add_new_doctor && (
                        <Link
                            to='/doctors/add'
                            className="btn btn-label-primary waves-effect waves-light d-flex align-items-center"
                        >
                            <i className="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none" />
                            <span className="d-none d-sm-inline-block">Add New Doctor</span>
                        </Link>
                    )}
                </div>

                <div className="card-datatable table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <td colSpan="7">
                                    <div className="d-flex justify-content-between align-items-center">
                                        {/* Dropdown for items per page */}
                                        <select
                                            className="form-select form-select-sm w-auto"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                        >
                                            <option value={5}>5 per page</option>
                                            <option value={10}>10 per page</option>
                                            <option value={15}>15 per page</option>
                                            <option value={20}>20 per page</option>
                                            <option value={25}>25 per page</option>
                                            <option value={30}>30 per page</option>
                                            <option value={40}>40 per page</option>
                                            <option value={50}>50 per page</option>
                                        </select>



                                        {/* Button for export to Excel */}
                                        <button className="btn btn-primary" onClick={exportToExcel}>
                                            Export to Excel
                                        </button>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th style={{ width: "5%" }}>S.No</th>
                                <th style={{ width: "20%" }}>Username</th>
                                <th style={{ width: "20%" }}>Name</th>
                                <th style={{ width: "15%" }}>Phone</th>
                                <th style={{ width: "15%" }}>Status</th>
                                <th style={{ width: "15%" }}>Onboarded</th>
                                <th style={{ width: "15%" }}>Actions</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by username"
                                        value={searchUsername}
                                        onChange={(e) => setSearchUsername(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by name"
                                        value={searchName}
                                        onChange={(e) => setSearchName(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by phone"
                                        value={searchPhone}
                                        onChange={(e) => setSearchPhone(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={searchStatus}
                                        onChange={(e) => setSearchStatus(e.target.value)}
                                    >
                                        <option value="">All Status</option>
                                        <option value="Verified">Verified</option>
                                        <option value="Not Verified">Not Verified</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={searchOnboarded}
                                        onChange={(e) => setSearchOnboarded(e.target.value)}
                                    >
                                        <option value="">All Onboarded</option>
                                        <option value="Onboarded">Onboarded</option>
                                        <option value="Not Onboarded">Not Onboarded</option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="7">Loading...</td>
                                </tr>
                            ) : doctors.length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="text-center">No data found</td>
                                </tr>
                            ) : (
                                doctors.map((doctor, index) => (
                                    <tr key={doctor.id}>
                                        <td>{indexOfFirstDoctor + index + 1}</td>
                                        <td>{doctor.username ? doctor.username : 'BHDRXXXX'}</td>
                                        <td>{doctor.first_name} {doctor.last_name}</td>
                                        <td>{doctor.phone}</td>
                                        <td className={doctor.verified ? "text-success" : "text-danger"}>
                                            {doctor.verified ? (
                                                <span className="badge bg-label-success rounded-pill">Verified</span>
                                            ) : (
                                                <span className="badge bg-label-danger rounded-pill">Not Verified</span>
                                            )}
                                        </td>
                                        <td className={doctor.onboarded ? "text-success" : "text-warning"}>
                                            {doctor.onboarded ? (
                                                <span className="badge bg-label-success rounded-pill">Onboarded</span>
                                            ) : (
                                                <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>
                                            )}
                                        </td>
                                        <td>
                                            <button className='btn' onClick={() => ViewDoctor(doctor.id)}>
                                                <i className="ri-eye-line"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="row mt-3">
                    <nav aria-label="Page navigation">
                        {renderPagination()}
                    </nav>
                </div>
            </div>
        </>
    );
}
