import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../components/utils/loggedin';

export default function AddLocation({ fetchLocations }) {
    const { token } = useAuth();
    const [location, setLocation] = useState({
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        longitude: "",
        latitude: "",
        status: ""
    });
    const [suggestions, setSuggestions] = useState([]); // Store the list of suggestions
    const [showSuggestions, setShowSuggestions] = useState(false); // Control suggestions dropdown visibility

    // Function to fetch location details by city
    const fetchLocationDetails = async (city) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/city/${city}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                }
            });

            if (response.data && response.data.locations) {
                setSuggestions(response.data.locations);
                setShowSuggestions(true);
            } else {
                setSuggestions([]);
                setShowSuggestions(false);
                toast.warning("No valid location data found for the given city.");
            }
        } catch (error) {
            toast.error("Error while fetching location by city:", error);
        }
    };


    // Function to fetch location details by pincode
    const fetchLocationDetailsByPinCode = async (pincode) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/${pincode}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                }
            });

            if (response.data && response.data.length > 0) {
                const locationData = response.data[0];

                // Map the fields from API response to your state
                setLocation(prevState => ({
                    ...prevState,
                    address: locationData.street || "",
                    city: locationData.city || "",
                    state: locationData.state || "",
                    zip: locationData.postalCode || "",
                    latitude: locationData.latitude || "",
                    longitude: locationData.longitude || "",

                }));
            } else {
                toast.warning("No valid location data found for the given pincode.");
            }
        } catch (error) {
            toast.error("Error while fetching location by pincode:", error);
        }
    };

    // Function to fetch location details by longitude and latitude
    const fetchLocationDetailsByLong = async (longitude, latitude) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/coords?longitude=${longitude}&latitude=${latitude}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                }
            });

            if (response.data) {
                const locationData = response.data;

                // Map the fields from API response to your state
                setLocation(prevState => ({
                    ...prevState,
                    address: locationData.sublocality || locationData.neighborhood || "",
                    city: locationData.city || "",
                    state: locationData.state || "",
                    zip: locationData.postalCode || "",
                    latitude: latitude,
                    longitude: longitude
                }));
            } else {
                toast.warning("No valid location data found for the given coordinates.");
            }
        } catch (error) {
            toast.error("Error while fetching location by coordinates:", error);
        }
    };

    // Function to handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocation(prevState => ({ ...prevState, [name]: value }));

        if (name === 'city' && value.length > 2) {
            fetchLocationDetails(value); // Fetch suggestions when city input has more than 2 characters
        } else if (name === 'zip' && value.length === 6) {
            fetchLocationDetailsByPinCode(value); // Fetch location details by zip when length is 6
        } else if ((name === 'longitude' || name === 'latitude')) {
            const longitude = name === 'longitude' ? value : location.longitude;
            const latitude = name === 'latitude' ? value : location.latitude;

            if (longitude && latitude && longitude.length > 0 && latitude.length > 0) {
                fetchLocationDetailsByLong(longitude, latitude); // Fetch details by longitude and latitude
            }
        }
    };

    // Update handleSuggestionClick to fill all fields including city
    const handleSuggestionClick = (suggestion) => {
        setLocation({
            name: suggestion.name || "",
            address: suggestion.address || "",
            city: suggestion.name || "", // Make sure city is filled from suggestion
            state: suggestion.state || "",
            zip: suggestion.pincode || "",
            latitude: suggestion.latitude || "",
            longitude: suggestion.longitude || ""
        });
        setShowSuggestions(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/location`, {
                address: location.address,
                city: location.city,
                state: location.state,
                zip: location.zip,
                latitude: location.latitude,
                longitude: location.longitude,
                status: location.status,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            toast.success("Location added successfully!");
            setLocation({ name: "", address: "", city: "", state: "", zip: "", longitude: "", latitude: "", status: "" }); // Reset the form
            fetchLocations();
        } catch (error) {
            toast.error("Error adding location:", error);
        }
    };

    return (
        <div>
            {/* Add Location Offcanvas */}
            <div
                className="offcanvas offcanvas-end addLocation"
                tabIndex={-1}
                id="offcanvasAddAddress"
                aria-labelledby="offcanvasEditDoctorLabel"
                style={{ width: '600px' }}
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasEditDoctorLabel" className="offcanvas-title">Add Location</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>

                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form className="add-new-user pt-0" id="editDoctor" onSubmit={handleSubmit}>
                        {/* City Field */}
                        <div className="form-floating form-floating-outline mb-5 position-relative">
                            <input
                                type="text"
                                id="edit-doctor-city"
                                className="form-control"
                                name="city"
                                value={location.city}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-city">City</label>

                            {/* Suggestions Dropdown */}
                            <div className="suggestions-container">
                                {showSuggestions && suggestions.length > 0 && (
                                    <ul className="suggestions-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleSuggestionClick(suggestion)}
                                                className="suggestion-item"
                                            >
                                                {suggestion.address} {suggestion.city}, {suggestion.state}, {suggestion.pincode}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <style jsx>{`
        .suggestions-container {
          position: relative;
          width: 100%;
        }
        .suggestions-list {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 1000;
          list-style-type: none;
          padding: 0;
          margin: 0;
          max-height: 200px;
          overflow-y: auto;
          background-color: #fff;
          border: 1px solid #ccc;
          border-top: none;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .suggestion-item {
          padding: 10px;
          cursor: pointer;
          border-bottom: 1px solid #eee;
        }
        .suggestion-item:last-child {
          border-bottom: none;
        }
        .suggestion-item:hover {
          background-color: #f0f0f0;
        }
      `}</style>
                            </div>

                        </div>

                        {/* Pincode Field */}
                        <div className="form-floating form-floating-outline mb-5 position-relative">
                            <input
                                type="text"
                                id="edit-doctor-zip"
                                className="form-control"
                                name="zip"
                                value={location.zip}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-zip">Zip Code</label>
                        </div>

                        {/* Address Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-address"
                                className="form-control"
                                name="address"
                                value={location.address}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-address">Address</label>
                        </div>

                        {/* State Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-state"
                                className="form-control"
                                name="state"
                                value={location.state}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-state">State</label>
                        </div>

                        {/* Latitude Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="number"
                                id="edit-doctor-latitude"
                                className="form-control"
                                name="latitude"
                                value={location.latitude}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-latitude">Latitude</label>
                        </div>

                        {/* Longitude Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="number"
                                id="edit-doctor-longitude"
                                className="form-control"
                                name="longitude"
                                value={location.longitude}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-longitude">Longitude</label>
                        </div>
                        {/* status  */}
                        <div className="form-floating form-floating-outline mb-5">
                            <select
                                id="edit-doctor-status"
                                className="form-select"
                                name="status"
                                value={location.status}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <label htmlFor="edit-doctor-status">Status</label>
                        </div>


                        {/* Submit Buttons */}
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary me-3"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="offcanvas"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Table to Display Locations */}


        </div >
    );
}
